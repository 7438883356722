




















































import { Component, Vue } from 'vue-property-decorator';
import * as api from '@/gen/api';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utils from '@/common/utils';
dayjs.extend(customParseFormat);

@Component({
  components: {}
})
export default class P0269 extends Vue {
  showContents = false;

  async created() {
    await this.initialize();
  }

  get requiredQueriesExist() {
    return (
      this.$route.query &&
      this.$route.query['uid'] &&
      this.$route.query['mail_address'] &&
      this.$route.query['expire_dt']
    );
  }

  async initialize() {
    if (!this.requiredQueriesExist) {
      // 必要なクエリパラメータが存在しない場合・クエリが有効でメール更新APIコール後にログアウトした場合は画面表示
      this.showContents = true;
      return;
    }
    if (!this.$auth.isAuthenticated) {
      // 必要なクエリパラメータが存在するがログアウト状態の場合はログイン画面に誘導する
      this.login();
      return;
    }
    // 必要なクエリパラメータが存在しログイン状態の場合はメールアドレス更新を行う
    // クエリパラメータを変数に格納
    const uid = decodeURIComponent(
      utils.getSingleQueryString(this.$route.query, 'uid')
    );
    const mailAddress = decodeURIComponent(
      utils.getSingleQueryString(this.$route.query, 'mail_address')
    );
    const expireDt = utils.getSingleQueryString(this.$route.query, 'expire_dt');
    // 現在ログインしているユーザの情報を取得
    const currentUid = this.$auth.user.sub;
    const currentMailAddress = this.$auth.user.email;
    // リロード対策のためクエリパラメータをurlから削除
    this.deleteQueryParams();
    // クエリパラメータが有効であるかチェック
    if (
      expireDt.length != 14 ||
      !dayjs(
        utils.getSingleQueryString(this.$route.query, 'expire_dt'),
        'YYYYMMDDHHmmss'
      ).isValid()
    ) {
      // 有効期限のフォーマットが正しいか
      this.returnToErrorScreen(this.$msg.get('2000522'));
      return;
    }
    if (dayjs(expireDt, 'YYYYMMDDHHmmss').isBefore(dayjs())) {
      // 有効期限が期間内か
      this.returnToErrorScreen(this.$msg.get('2000521'));
      return;
    }
    if (uid !== currentUid) {
      // ログイン中のユーザのUIDと一致するか
      this.returnToErrorScreen(this.$msg.get('2000522'));
      return;
    }
    if (mailAddress === currentMailAddress) {
      // ログイン中のユーザのメールアドレスと重複していないか
      this.returnToErrorScreen(this.$msg.get('2000523'));
      return;
    }
    // メールアドレス更新APIのコール
    const res = await this.updateCurrentUserEmail(mailAddress);
    if (res === undefined) {
      // error handled
      return;
    }
    // ログアウト
    this.logout();
  }

  // エラー画面にログアウトして遷移する
  returnToErrorScreen(errorMsg: string) {
    const errorPageURI = `${window.location.origin}/error?error_description=`;
    // Auth0にリダイレクトURIを設定する際に日本語は弾かれるためエンコードを行う
    const returnTo = errorPageURI + encodeURI(errorMsg);
    this.$auth.logout({
      returnTo: returnTo
    });
  }

  // クエリパラメータを消したパスでリプレイス
  deleteQueryParams() {
    this.$router.replace({ query: {} });
  }

  // メールアドレス変更API
  async updateCurrentUserEmail(newEmail: string) {
    const config = await this.$getConfigWithToken(this.$auth);
    const curUserApi = new api.CurrentUserApi(config);
    return await curUserApi
      .updateCurrentUserEmail({
        email: newEmail
      })
      .catch((err: AxiosError) => {
        this.handleupdateCurrentUserEmailErr(err);
      });
  }

  // メールアドレス更新時のエラーハンドリング
  handleupdateCurrentUserEmailErr(err: AxiosError) {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          this.returnToErrorScreen(this.$msg.get('2000523'));
          break;
        case 401:
          this.$tokenErrHandler(err);
          break;
        default:
          // 想定されている500と、それ以外のエラーが意図せず返った場合
          this.returnToErrorScreen(this.$msg.get('2000011'));
      }
    }
  }

  // ログアウト
  logout() {
    // 明示的にLogout後のURLを指定
    const returnTo = `${window.location.origin}${this.$route.path}`;
    this.$auth.logout({
      returnTo,
      opAppLogout: true,
      opAppLogoutMessage: this.$msg.get('2000512')
    });
  }

  // ログイン
  login() {
    this.$auth.loginWithRedirect({
      appState: { targetUrl: this.$route.fullPath }
    });
  }
}
